/**
 * @author Karl Jones
 * @email karljones@live.ie
 * @create date 2022-12-01 13:51:39
 * @modify date 2023-05-11 10:01:14
 * @desc this is a config file that is used with the theme provider from material-ui, this allows for customisation of the theme aswell as enabling certain functions throughout the application
 */

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#7bad70'
        }
    },
    typography: {
        fontFamily: [
            'SourceSansPro',
            '--apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
    }
})

export { theme }