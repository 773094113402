import { Link } from 'react-router-dom';
import { DeviceDetails } from '../../../functions/DeviceDetails';

import Button from '@mui/material/Button';
import ErrorBoundary from '../states/ErrorBoundary';

interface IStandardButton {
    content: string,
    classes?: string,
    href?: string,
    ariaLabel: string,
    variant?: "text" | "outlined" | "contained" | undefined,
    startIcon?: React.ReactNode,
    endIcon?: React.ReactNode,
    fullWidth?: boolean,
    type?: "button" | "submit" | "reset" | undefined,
    borderRadius?: string,
    size?: "small" | "medium",
    onClick?: () => void,
}

const StandardButton = (props: IStandardButton) => {
    return (
        <div data-testid="StandardButtonComponent" className={`standard-button ${props.classes}`} style={{ width: props.fullWidth ? '100%' : 'auto' }}>
            <ErrorBoundary>
                <Link to={props.href ? props.href : '#'}>
                    <Button
                        className={`standard-button ${props.variant} ${props.classes}`}
                        fullWidth={props.fullWidth ? props.fullWidth : true}
                        aria-label={props.ariaLabel}
                        variant={props.variant ? props.variant : "outlined"}
                        startIcon={props.startIcon}
                        endIcon={props.endIcon}
                        type={props.type ? props.type : "button"}
                        style={{ borderRadius: props.borderRadius ? props.borderRadius : '4px' }}
                        onClick={props.onClick ? props.onClick : undefined}
                        size={props.size ? props.size : DeviceDetails.isMobileDevice() ? 'small' : 'medium'}
                        name={props.content}>
                        {props.content}
                    </Button>
                </Link>
            </ErrorBoundary>
        </div >
    )
}

export default StandardButton;