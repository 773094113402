import { DeviceDetails } from '../../../functions/DeviceDetails';

import StandardButton from '../../common/button/StandardButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const NotFound = () => {
    return (
        <div data-testid="NotFoundPage" className="not-found-page app-page" style={{ textAlign: 'center' }}>
            <Typography variant="h1" style={{ fontSize: '24px', marginTop: '32px' }} gutterBottom>
                The content that you're trying to load has not been found.
            </Typography>
            <Typography variant="body1" style={{ fontSize: '16px' }} gutterBottom>
                This may be because the content has moved, or never existed in the first place.
            </Typography>
            <Stack direction={DeviceDetails.isMobileDevice() ? 'column' : 'row'} spacing={2} style={{ marginTop: '32px' }} alignItems="center" justifyContent="center">
                <StandardButton
                    content="Return to home"
                    href="/"
                    ariaLabel="Content not found - return to homepage"
                    variant="contained"
                    size="small" />
                <StandardButton
                    content="Let us know"
                    href="/contact"
                    ariaLabel="let us know - go to contact page"
                    size="small" />
            </Stack>
        </div>
    )
}

export default NotFound;