import ErrorBoundary from "../states/ErrorBoundary"

interface IApplePodcastEmbed {
    title: string;
    src: string;
    height?: string;
}

const ApplePodcastEmbed = ({ title, src, height = "450px" }: IApplePodcastEmbed) => {
    return (
        <div data-testid="ApplePodcastEmbedComponent" className="apple-podcast-embed">
            <ErrorBoundary>
                <iframe
                    title={`Apple Podcasts - ${title}`}
                    id="apple-podcast-embed"
                    src={src}
                    height={height}
                    frameBorder="0"
                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
                    allow="autoplay *; encrypted-media *; clipboard-write"
                    loading="lazy"
                    style={{
                        width: '100%',
                        maxWidth: '660px',
                        overflow: 'hidden',
                        borderRadius: '10px',
                        transform: 'translateZ(0px)',
                        animation: '2s ease 0s 6 normal none running loading-indicator',
                        backgroundColor: 'rgb(228, 228, 228)'
                    }}>

                </iframe>
            </ErrorBoundary>
        </div>
    )
}

export default ApplePodcastEmbed;