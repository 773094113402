import { DeviceDetails } from "../../../../functions/DeviceDetails";

import ErrorBoundary from "../../../common/states/ErrorBoundary";
import ApplePodcastEmbed from '../../../common/embeds/ApplePodcastEmbed';
import SpotifyPodcastEmbed from '../../../common/embeds/SpotifyPodcastEmbed';

interface IPodcastEmbed {
    showDeviceSpecificEmbeds?: boolean;
    title: string;
    embedLinks: {
        apple: string;
        spotify: string;
    };
}

const PodcastEmbed = ({ showDeviceSpecificEmbeds = true, title, embedLinks }: IPodcastEmbed) => {
    return (
        <div data-testid="PodcastEmbedComponent" className="podcast-embed">
            <ErrorBoundary>
                {showDeviceSpecificEmbeds
                    ? DeviceDetails.isAppleDevice()
                        ? <ApplePodcastEmbed title={title} src={`${embedLinks.apple}?theme=automatic`} />
                        : <SpotifyPodcastEmbed title={title} src={embedLinks.spotify} />
                    : <ApplePodcastEmbed title={title} src={embedLinks.apple} />
                }
            </ErrorBoundary>
        </div>
    )
}

export default PodcastEmbed;