import { useState } from 'react';
import { sluggify } from '../../../functions/stringUtils';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { DeviceDetails } from '../../../functions/DeviceDetails';

import headerData from '../../../config/header_navigation.json';
import announcementBarData from '../../../config/announcement_bar_config.json';

import AnnouncementBar from '../AnnouncementBar';
import AppBar from '@mui/material/AppBar';
import ErrorBoundary from '../states/ErrorBoundary';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import RainbowBar from '../misc/RainbowBar';
import SegmentIcon from '@mui/icons-material/Segment';

const Header = () => {
    const [mobileNavigationOpen, setMobileNavigationOpen] = useState(false);

    const getNavigationItemIcon = (navigationIcon: string) => {
        const styles = { fontSize: '1.1rem', display: 'flex' }
        switch (navigationIcon) {
            case 'contact': return <EmailOutlinedIcon style={styles} />;
            case 'store': return <ShoppingBasketOutlinedIcon style={styles} />;
            default: return null;
        }
    }

    const navigate = useNavigate();

    const handleMobileNavigationItemClick = (e: any, path: string) => {
        e.preventDefault();
        setMobileNavigationOpen(false);

        // Check if the link is an external link, if so, open it in a new tab and focus on that new tab
        if (path.startsWith("http")) {
            var tab = window.open(path, '_blank');
            tab?.focus();
        } else {
            navigate(path);
        }
    }

    const pathname = useLocation().pathname;

    const shouldAnnouncementBarShow = () => {
        for (var i = 0; i < announcementBarData.pages_to_exclude.length; i++) {
            if (pathname === announcementBarData.pages_to_exclude[i]) {
                return false;
            }
        }
        return announcementBarData.enabled;
    }

    return (
        <>
            <AppBar
                data-testid="HeaderComponent"
                className="header navigation"
                style={{
                    backgroundColor: 'var(--header-background-color)',
                    paddingTop: 'env(safe-area-inset-top)',
                    backgroundImage: 'unset'
                }}
                position="fixed">
                <Toolbar
                    className="header-toolbar"
                    style={{
                        width: '100%',
                        margin: '0 auto'
                    }}>
                    <Grid container>
                        <Grid item xs={9} sm={3}>
                            <Link to="/" title="Home" aria-label="Navigate to home page">
                                <img
                                    data-testid="HeaderIcon"
                                    className="header-icon"
                                    src="https://ucarecdn.com/4bdc65c5-3c4d-42e6-9241-f485c868d5d7/-/quality/smart/-/format/webp/"
                                    alt="Header icon"
                                    aria-label="Header icon"
                                    style={{
                                        maxWidth: '100%',
                                        height: '35px'
                                    }} />
                            </Link>
                        </Grid>
                        {DeviceDetails.isDesktopDevice() &&
                            <Grid item xs={9}>
                                <ErrorBoundary>
                                    <Stack
                                        data-testid="HeaderNavigation"
                                        direction="row"
                                        spacing={3}
                                        style={{ height: 'calc(100% - 12px)', marginBottom: '12px' }}
                                        justifyContent="flex-end"
                                        alignItems="flex-end">
                                        {headerData.navigation.map((nav, index) => {
                                            return (
                                                <div key={index}>
                                                    {
                                                        nav.path.startsWith("http")
                                                            ? <a
                                                                className={`header-navigation-item header-navigation-item-desktop header-navigation-item-${sluggify(nav.title)}`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                href={nav.path}
                                                                title={nav.title}
                                                                aria-label={`Navigate to ${nav.title.toLowerCase()} page`} >
                                                                {nav.icon ? getNavigationItemIcon(nav.icon as string) : nav.title}
                                                            </a>
                                                            : <NavLink
                                                                className={`header-navigation-item header-navigation-item-desktop header-navigation-item-${sluggify(nav.title)}`}
                                                                to={nav.path}
                                                                title={nav.title}
                                                                aria-label={`Navigate to ${nav.title.toLowerCase()} page`}>
                                                                {nav.icon ? getNavigationItemIcon(nav.icon as string) : nav.title}
                                                            </NavLink>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </Stack>
                                </ErrorBoundary>
                            </Grid>
                        }
                        {DeviceDetails.isMobileDevice() &&
                            <Grid item xs={3}>
                                <div data-testid="HeaderMobileNavigationButton" style={{ height: '100%', textAlign: 'end' }}>
                                    <IconButton
                                        onClick={() => setMobileNavigationOpen((mobileNavigationOpen) => !mobileNavigationOpen)}
                                        name="menu"
                                        aria-label="Open mobile navigation menu"
                                        aria-controls={mobileNavigationOpen ? 'menu' : undefined}
                                        aria-haspopup="true"
                                        aira-expanded={mobileNavigationOpen ? 'true' : undefined}>
                                        <SegmentIcon />
                                    </IconButton>
                                </div>
                            </Grid>
                        }
                    </Grid>

                </Toolbar>

                <RainbowBar />

                {shouldAnnouncementBarShow() && <AnnouncementBar />}
            </AppBar >

            <Box component="nav">
                <Drawer
                    className="mobile-navigation-drawer"
                    variant="temporary"
                    open={mobileNavigationOpen}
                    onClose={() => setMobileNavigationOpen(false)}
                    anchor="right"
                    ModalProps={{
                        keepMounted: DeviceDetails.isMobileDevice() ? true : false,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "50%" },
                    }}>
                    <Box
                        data-testid="MobileNavigationComponent"
                        className="mobile-navigation-drawer-content-container"
                        style={{ minHeight: '100%' }}
                        role="presentation">
                        <Stack direction="column">
                            <div className="mobile-navigation-drawer-logo-container">
                                <img
                                    className="mobile-navigation-drawer-logo"
                                    src="https://ucarecdn.com/95dc0552-0705-4672-b9c7-65acf972a9f3/-/quality/smart/-/format/auto/"
                                    alt="Mobile navigation logo" />
                            </div>

                            {headerData.navigation.map((nav, index) => {
                                return (
                                    <Typography
                                        className={`header-navigation-item header-navigation-item-mobile header-navigation-item-${sluggify(nav.title)}`}
                                        key={index}
                                        // to={nav.path}
                                        onClick={(e) => handleMobileNavigationItemClick(e, nav.path)}
                                        title={nav.title}
                                        aria-label={`Navigate to ${nav.title.toLowerCase()} page`}>
                                        {nav.title}
                                    </Typography>
                                )
                            })}
                        </Stack>
                    </Box>
                </Drawer>
            </Box>
        </>
    )
}

export default Header;