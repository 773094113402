import { useState } from 'react';
import { Link } from 'react-router-dom';
import { DeviceDetails } from '../../functions/DeviceDetails';

import configData from '../../config/announcement_bar_config.json';

import ErrorBoundary from './states/ErrorBoundary';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const AnnouncementBar = () => {
    const [show, setShow] = useState(configData.enabled && sessionStorage.getItem('announcement_bar_dismissed') !== 'true');

    if (!show) {
        return null;
    }

    const handleBodyClasses = () => {
        if (configData.enabled) {
            document.body.setAttribute("data-announcement-bar", "true");
            if (configData.cta && configData.cta_link) {
                document.body.setAttribute("data-announcement-bar-cta", "true");
            } else {
                document.body.removeAttribute("data-announcement-bar-cta");
            }
        } else {
            document.body.removeAttribute("data-announcement-bar");
            document.body.removeAttribute("data-announcement-bar-cta");
        }
    }

    handleBodyClasses();

    const handleDismiss = () => {
        setShow(false);
        sessionStorage.setItem('announcement_bar_dismissed', 'true');
        document.body.removeAttribute("data-announcement-bar");
        document.body.removeAttribute("data-announcement-bar-cta");
    }

    return (
        <ErrorBoundary>
            <div data-testid="AnnouncementBarComponent" className="announcement-bar" style={{ color: 'white', fontSize: '16px', textDecoration: 'none' }}>
                <div className="announcement-bar-container">
                    {DeviceDetails.isDesktopDevice() &&
                        <>
                            {(configData.cta && configData.cta_link) &&
                                <Typography
                                    data-testid="AnnouncementBarContent"
                                    className="announcement-bar-content"
                                    variant="body1"
                                    style={{ fontSize: '16px' }}>
                                    {configData.message} &bull; <Link style={{ color: 'inherit' }} to={configData.cta_link} target={configData.cta_link.startsWith('http') ? '_blank' : '_self'}>{configData.cta}</Link> or <a href='#' onClick={handleDismiss} style={{ color: 'inherit', cursor: 'pointer' }}>dismiss</a>
                                </Typography>
                            }

                            {!configData.cta &&
                                <Typography
                                    className="announcement-bar-content"
                                    variant="body1"
                                    style={{ fontSize: '16px' }}>
                                    {configData.message}  or <a href='#' onClick={handleDismiss} style={{ color: 'inherit', cursor: 'pointer' }}>dismiss</a>
                                </Typography>
                            }
                        </>
                    }

                    {DeviceDetails.isMobileDevice() &&
                        <>
                            <Typography
                                className="announcement-bar-content-mobile"
                                variant="body1"
                                style={{ fontSize: '16px' }}>
                                {configData.message}
                            </Typography>
                            {configData.cta &&
                                <>
                                    <Link style={{ color: 'inherit' }} to={configData.cta_link} target={configData.cta_link.startsWith('http') ? '_blank' : '_self'}>
                                        {configData.cta}
                                    </Link>
                                    <IconButton onClick={handleDismiss} size="small"><CloseIcon fontSize="small" /></IconButton>
                                </>
                            }
                        </>
                    }
                </div>
            </div >
        </ErrorBoundary>
    )
}

export default AnnouncementBar;