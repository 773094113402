export class DevHelper {
    private static isLocalhost() {
        return window.location.hostname === "localhost";
    }

    static isBeta() {
        return window.location.hostname.includes("beta");
    }

    static print(type: "info" | "log" | "warn" | "error", ...args: any[]) {
        if (this.isLocalhost()) {
            switch (type) {
                case "info":
                    console.info(...args);
                    break;
                case "log":
                    console.log(...args);
                    break;
                case "warn":
                    console.warn(...args);
                    break;
                case "error":
                    console.error(...args);
                    break;
                default:
                    console.log(...args);
            }
        }
    }
}