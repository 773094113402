import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';

interface IChipHeading {
    content: string;
}

const ChipHeading = ({ content }: IChipHeading) => {
    // If no content is provided, return null
    if (!content) return null;

    return (
        <Divider data-testid="ChipHeadingComponent" className="chip-heading" style={{ margin: '32px 0' }}>
            <Chip label={content.toUpperCase()} className="general-heading-chip" />
        </Divider>
    )
}

export default ChipHeading;