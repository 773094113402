import { Helmet } from "react-helmet";

interface ICustomHelmet {
    title?: string;
    description?: string;
    themeColor?: string;
    twitterImage?: string;
    twitterSite?: string;
    children?: React.ReactNode;
}

const CustomHelmet = ({
    title,
    description,
    themeColor,
    twitterImage,
    twitterSite = "@muff_inn",
    children }: ICustomHelmet) => {

    const getTitle = () => { return title ? title + " | Paige Hart" : "Paige Hart" };

    return (
        <Helmet>
            {/** Title */}
            <title>{getTitle()}</title>
            <meta name="og:title" content={getTitle()} />

            {/** Description */}
            {description && <meta name="description" content={description} />}
            {description && <meta name="og:description" content={description} />}

            {/** Twitter */}
            <meta name="twitter:title" content={getTitle()} />
            {description && <meta name="twitter:description" content={description} />}
            <meta name="twitter:card" content="summary" />
            {twitterImage && <meta name="twitter:image" content={twitterImage} />}
            {twitterSite && <meta name="twitter:site" content={twitterSite} />}

            {/** Misc */}
            <link rel="canonical" href={window.location.href} />
            <meta name="og:url" content={window.location.href} />
            {themeColor && <meta name="theme-color" content={themeColor} />}

            {children}
        </Helmet>
    )
}

export default CustomHelmet;