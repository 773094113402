import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DeviceDetails } from '../../../functions/DeviceDetails';

import pageConfig from '../../../config/pages/page_config_podcast.json';
import podcasts from '../../../config/podcasts/podcasts.json';

import ChipHeading from '../../common/text/ChipHeading';
import CustomHelmet from '../../common/CustomHelmet';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import SpeechBubble from '../../common/text/SpeechBubble';
import PodcastEmbed from './components/PodcastEmbed';
import NotFound from '../notfound/NotFound';

const Podcast = () => {
    const [podcast, setPodcast] = useState<any>();
    const { shortcode } = useParams();

    useEffect(() => {
        if (shortcode)
            setPodcast(podcasts[shortcode]);
    }, [shortcode]);

    if (!podcast) {
        return <NotFound />;
    }

    return (
        <div data-testid="PodcastPage" className="podcast-page app-page">
            <CustomHelmet
                title={podcast.details.title}
                description={podcast.description[0]}
                twitterImage="/share.jpg"
                twitterSite={podcast.social.twitter} />

            <div className="podcast-page-image-container">
                <img
                    className="podcast-page-image"
                    src={podcast.media.cover}
                    alt={`${podcast.details.title} icon`} />
            </div>

            <div className="podcast-page-description">
                {podcast.description.map((paragraph: string, index: number) => {
                    return (
                        <Typography key={index} variant="body1" style={{ marginBottom: '16px' }}>
                            {paragraph}
                        </Typography>
                    )
                })}

                {pageConfig.features?.question_display?.enabled && podcast.samples.map((sample: string, index: number) => {
                    return (
                        <SpeechBubble key={index} content={sample} alignment={pageConfig.features?.question_display?.alignments[index]} />
                    )
                })}

                <Typography variant="body1" style={{ margin: '32px 0' }}>
                    {`Subscribe to ${podcast.details.title} today and join the conversation!`}
                </Typography>
            </div>

            {pageConfig.features?.podcast_embeds?.enabled &&
                <div className="podcast-page-subscribe-links">
                    <ChipHeading content="listen & subscribe" />

                    <div style={{ textAlign: 'center', marginTop: '16px' }}>
                        {pageConfig.features?.podcast_embeds?.enabled &&
                            <PodcastEmbed
                                showDeviceSpecificEmbeds={pageConfig.features?.podcast_embeds?.show_device_specific_embeds}
                                title={podcast.details.title}
                                embedLinks={podcast.links.embeds} />
                        }

                        <div style={{ marginTop: '32px' }}>
                            <ChipHeading content="More ways to listen" />
                        </div>

                        <Stack
                            direction="row"
                            spacing={2}
                            style={{ marginTop: '32px', marginBottom: '64px' }}
                            alignItems="center"
                            justifyContent="center">
                            <Button
                                className="podcast-page-additional-link"
                                href={DeviceDetails.isAppleDevice() ? podcast.links.spotify : podcast.links.apple}
                                target="_blank"
                                title={`View podcast on ${DeviceDetails.isAppleDevice() ? 'Spotify' : 'Apple Podcasts'}`}
                                aria-label={`View podcast on ${DeviceDetails.isAppleDevice() ? 'Spotify' : 'Apple Podcasts'}`}
                                rel="noopener noreferrer"
                                variant="outlined">
                                {DeviceDetails.isAppleDevice() ? 'Spotify' : 'Apple Podcasts'}
                            </Button>
                            <Button
                                className="podcast-page-additional-link"
                                href={podcast.links.google}
                                target="_blank"
                                title="View podcast on Google Podcasts"
                                aria-label="View podcast on Google Podcasts"
                                rel="noopener noreferrer"
                                variant="outlined">
                                Google Podcasts
                            </Button>
                        </Stack>
                    </div>
                </div>
            }
        </div>
    )
}

export default Podcast;