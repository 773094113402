import ErrorBoundary from "../states/ErrorBoundary";

interface ISpotifyPodcastEmbed {
    title: string;
    src: string;
    height?: string;
}

const SpotifyPodcastEmbed = ({ title, src, height = "152" }: ISpotifyPodcastEmbed) => {
    return (
        <div data-testid="SpotifyPodcastEmbedComponent" className="spotify-podcast-embed">
            <ErrorBoundary>
                <iframe
                    title={`Spotify Podcasts - ${title}`}
                    id="spotify-podcast-embed"
                    className="spotify-podcast-embed"
                    style={{ borderRadius: '12px', maxWidth: '660px' }}
                    src={src}
                    width="100%"
                    height={height}
                    frameBorder="0"
                    allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy">

                </iframe>
            </ErrorBoundary>
        </div>
    )
}

export default SpotifyPodcastEmbed;