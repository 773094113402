import LinearProgress from '@mui/material/LinearProgress'

const LoadingState = () => {
    return (
        <div
            data-testid="LoadingState"
            className="loading-state"
            style={{
                width: '100%',
                margin: '64px 0'
            }}>
            <LinearProgress />
        </div>
    )
}

export default LoadingState;