import { DeviceDetails } from '../../../functions/DeviceDetails';

import pageConfig from '../../../config/pages/page_config_home.json';
import greetings from '../../../config/misc/home_page_greetings.json';

import CustomHelmet from '../../common/CustomHelmet';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import BookWidget from '../../common/widgets/BookWidget';

const Home = () => {
    const getGreetingByTimeOfDay = () => {
        const date = new Date();
        const hour = date.getHours();

        if (hour >= 0 && hour < 12) {
            return greetings.morning;
        } else if (hour >= 12 && hour < 18) {
            return greetings.afternoon;
        } else {
            return greetings.evening;
        }
    }

    return (
        <div data-testid="HomePage" className="home-page app-page">
            <CustomHelmet />

            <Grid className="home-content-container" container spacing={3} style={{ marginBottom: '50px' }} alignItems="center" direction={DeviceDetails.isMobileDevice() ? "column" : "row"} >
                {DeviceDetails.isMobileDevice() &&
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <img
                            className="home-introduction-img-mobile"
                            height="300"
                            width="auto"
                            src="https://ucarecdn.com/85775791-44c1-40cf-b40a-70730412865d/-/quality/smart/-/format/webp/"
                            alt="Drawn portrait of Paige Hart" />
                    </Grid>
                }
                <Grid item xs={12} md={8} >
                    <div className="home-introduction">
                        <Typography className="home-introduction-title" variant="h1" style={{ fontSize: '32px' }} gutterBottom>
                            {getGreetingByTimeOfDay()}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Come on in, grab a hot drink, relax and allow me to introduce myself.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            My name is Paige, I am from Portsmouth, England and I am an artist, and a writer.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Poetry is my biggest love. It has always been an escape for me, a way to empty my head of all the thoughts and feeling jumbled inside and try to present them in a way that is not only therapeutic for me, but is hopefully also relatable for the reader.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            For years, the idea of letting my words out into the world terrified me. The thought of broadcasting what is my heart and soul to everybody left me cowering in a corner, clutching my notebook to my chest and refusing to let anybody see. But as I’ve grown, and felt my writing grow alongside me, I’m ready to open up to the world and allow everybody to see.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            So here I am, saying hello to the world and opening up this site as a safe space for everybody to enjoy, a place for me to pour my poetry into the world, share bits and pieces of me and my mind and just write.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            I’m thrilled to have you along for the ride!
                        </Typography>
                        <img
                            className="home-introduction-signature"
                            height="100"
                            width="auto"
                            src="https://ucarecdn.com/4aeaf16b-71c3-4df1-9c95-d18b9e016469/-/quality/smart/-/format/webp/"
                            alt="Paige Hart signature" />
                    </div>
                </Grid>
                {DeviceDetails.isDesktopDevice() &&
                    <Grid item md={4}>
                        <img
                            className="home-introduction-img-desktop"
                            height="auto"
                            width="100%"
                            style={{
                                maxWidth: '100%'
                            }}
                            src="https://ucarecdn.com/85775791-44c1-40cf-b40a-70730412865d/-/quality/smart/-/format/webp/"
                            alt="Drawn portrait of Paige Hart" />
                    </Grid>
                }
            </Grid>

            {pageConfig.features.upcoming_release.enabled &&
                <BookWidget type="upcoming" showChipHeading />
            }

            {pageConfig.features.latest_release.enabled &&
                <BookWidget type="latest" showChipHeading />
            }
        </div>
    )
}

export default Home;