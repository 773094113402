import { DateHelper } from "../../../utils/DateHelper";

const RainbowBar = () => {
    if (!DateHelper.isPrideMonth()) return null;

    return (
        <div data-testid="RainbowBarComponent" className="rainbow-bar" />
    )
}

export default RainbowBar;