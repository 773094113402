import { useMemo } from 'react';
import { DeviceDetails } from '../../../functions/DeviceDetails';

import books from '../../../config/books/books.json';

import ErrorBoundary from '../states/ErrorBoundary';
import ChipHeading from "../text/ChipHeading";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import StandardButton from '../button/StandardButton';
import Paper from '@mui/material/Paper';

interface IBookWidget {
    bookShortcode?: string;
    type?: "upcoming" | "latest" | undefined;
    showChipHeading?: boolean;
}

const BookWidget = (props: IBookWidget) => {
    const getBookByType = () => {
        switch (props.type) {
            case "upcoming":
                if (books.upcoming_book) {
                    return books[books.upcoming_book];
                }
                return null;
            case "latest":
                return books[books.latest_book];
            default:
                if (props.bookShortcode) {
                    return books[props.bookShortcode];
                }
                return null;
        }
    }

    const book = useMemo(() => getBookByType(), [props.type, props.bookShortcode]);

    if (!book) {
        return null;
    }

    return (
        <ErrorBoundary>
            <div data-testid="BookWidgetComponent" className={`book-widget book-widget-${props.type} ${book}`} >

                {((props.type === "upcoming" || props.type === "latest") && (props.showChipHeading ? props.showChipHeading : true)) &&
                    <ChipHeading content={`${props.type} release`} />
                }

                <Paper style={{ padding: '16px' }}>

                    {DeviceDetails.isDesktopDevice() &&
                        <Grid container className="book-widget-container" spacing={2} direction="row">
                            <Grid item md={3} lg={2}>
                                <img
                                    className="book-widget-image-desktop"
                                    src={book.media.cover}
                                    alt={`${book.details.title} cover`}
                                    loading="lazy" />
                            </Grid>
                            <Grid item md={9} lg={10}>
                                <Typography className="book-widget-title" variant="h5">
                                    {book.details.title}
                                </Typography>
                                <Typography className="book-widget-author" variant="overline">
                                    {new Intl.ListFormat("en", { style: 'long', type: 'conjunction' }).format(book.details.author)} &bull; <time dateTime={book.details.publish_year}>{book.details.publish_year}</time> {props.type === "upcoming" && "(expected)"}
                                </Typography>
                                <br />
                                <Typography variant="body1" className="book-widget-description" style={{ whiteSpace: 'pre-line' }}>
                                    {book.short_description}
                                </Typography>

                                <StandardButton content="Learn more" href={`/books/${book.details.shortcode}`} fullWidth ariaLabel={`Learn more about ${book.details.title}`} />
                            </Grid>
                        </Grid>
                    }

                    {DeviceDetails.isMobileDevice() &&
                        <>
                            <Grid container spacing={2} direction="row" alignItems="center">
                                <Grid item xs={4}>
                                    <img
                                        className="book-widget-image-mobile"
                                        src={book.media.cover}
                                        alt={`${book.details.title} cover`} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography className="book-widget-title" variant="h5">
                                        {book.details.title}
                                    </Typography>
                                    <Typography className="book-widget-author-mobile" variant="overline">
                                        {new Intl.ListFormat("en", { style: 'long', type: 'conjunction' }).format(book.details.author)} &bull; {book.details.publish_year}
                                    </Typography>
                                    <StandardButton
                                        content="Learn more"
                                        href={`/books/${book.details.shortcode}`}
                                        fullWidth
                                        ariaLabel={`Learn more about ${book.details.title}`}
                                        classes="book-widget-learn-more-mobile" />
                                </Grid>
                            </Grid>
                            <div className="book-widget-description-mobile">
                                {book.short_description}
                            </div>
                        </>
                    }
                </Paper>
            </div>
        </ErrorBoundary>
    )
}

export default BookWidget;