/**
 * Shorten a string of characters to fit screens where not all of the information is required.
 * @param toShorten string to shorten
 * @param limit the number of characters to return
 * @returns a shortened string
 */
export function shortenString(toShorten: string, limit: number) {
    return toShorten.length > limit ? toShorten.substring(0, (limit - 3)) + "..." : toShorten;
}

/**
 * Combine an array of strings into a single string.
 * @param toCombine array of strings to combine
 * @param delimiter what to join the strings with
 * @returns a single string, combined with the delimiter
 */
export function stringifyArray(toCombine: string[], delimiter: string) {
    if (!delimiter) delimiter = " ";

    return toCombine.join(delimiter)
}

/**
 * Slice the string and return the first instance of the sliced text, or the first two if there are multiple
 * @param sliceOn the character to slice the string on
 * @param toSlice the string to slice
 * @returns the first instance of the sliced text, or the first two if there are multiple
 */
export function sliceString(toSlice: string, sliceOn: string) {
    var slices = toSlice.split(sliceOn);
    if (slices.length > 1) return slices[0] + "<br /> <br />" + slices[1]
    return slices[0];
}

/**
 * Validate if a string only includes letters and hyphens
 * @param toValidate string to validate
 * @returns true if valid, false otherwise
 */
export function validateStringNoSpecialCharacters(toValidate: string) {
    return toValidate === "" || /^[a-zA-Z\s-]+$/.test(toValidate);
}

/**
 * Validate an email address to ensure that it works as expected
 * @param toValidate the email to validate
 * @returns true if valid, false otherwise
 */
export function validateEmail(toValidate: string) {
    return toValidate === "" || /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(toValidate);
}

/**
 * Check if a string has the presence of any code
 * @param toValidate the string to validate
 * @returns true if there is code, false otherwise
 */
export function validateStringNoCode(toValidate: string) {
    return /\b(function|for|while|if|else|return)\b/.test(toValidate);
}

/**
 * Sluggify a string, used for classes and urls
 * @param {String} str - The string to sluggify
 * @returns sluggified string
 */
export const sluggify = (str = "", delimiter = '-') => {
    return str.toLowerCase().replace(/ /g, delimiter);
}
