import { DeviceDetails } from "../../../functions/DeviceDetails";

interface ISpeechBubble {
    content: string;
    alignment?: string;
}

const SpeechBubble = ({ content, alignment = "right" }: ISpeechBubble) => {
    // Alignment is ignored on mobile devices
    if (DeviceDetails.isMobileDevice()) alignment = "center";

    return (
        <div data-testid="SpeechBubbleComponent" className={`speech-bubble ${alignment}`}>
            <p className={`speech-bubble-content ${alignment}`}>{content}</p>
        </div>
    )
}

export default SpeechBubble;