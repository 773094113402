import CustomHelmet from '../../common/CustomHelmet';
import Stack from '@mui/material/Stack';
import BookWidget from '../../common/widgets/BookWidget';

const Books = () => {
    return (
        <div data-testid="BooksPage" className="books-page app-page">
            <CustomHelmet title="Books" description="Learn more about the books written by Paige Hart." />

            <Stack data-testid="BooksPage-BookList" spacing={2}>
                <BookWidget bookShortcode="pain-and-perseverance" />

                <BookWidget bookShortcode="catharsis" />

                <BookWidget bookShortcode="forged-from-tragedy" />
            </Stack>
        </div>
    )
}

export default Books;