import Typography from '@mui/material/Typography';

const Footer = () => {
    return (
        <footer data-testid="FooterComponent" className="footer footer-container">
            <Typography variant="overline" style={{ padding: '32px 16px 16px 16px' }}>
                Copyright Paige Hart 2021 - {new Date().getFullYear()}
            </Typography>
        </footer>
    )
}

export default Footer;