/**
 * @author Karl Jones
 * @email karljones@live.ie
 * @create date 2022-12-10 20:43:41
 * @modify date 2022-12-10 20:43:41
 * @desc this sets out the template that is used to display content to the user, this will hold all content and display the header
 */

import { useEffect, Suspense } from 'react'
import { Outlet } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import ErrorBoundary from '../common/states/ErrorBoundary';
import Header from "../common/header/Header";
import LoadingState from '../common/states/LoadingState';
import Footer from '../common/footer/Footer';

const Layout = () => {
    const pathname = useLocation().pathname;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <div data-testid="Layout" style={{ minHeight: 'calc(100vh - 100px)' }}>
                <ErrorBoundary>
                    <Header />
                </ErrorBoundary>

                <Suspense fallback={<LoadingState />}>
                    <ErrorBoundary>
                        <Outlet />
                    </ErrorBoundary>
                </Suspense>
            </div>

            <Footer />
        </>
    )
}

export default Layout;